// Used for sending events to Google Analytics
//
// Usage:
// <a
//   href="/some/path"
//   data-controller="analytics"
//   data-action="analytics#publish:once"
//   data-analytics-event-value="event_name"
//   data-analytics-value-value="optional_value"
// >
//   Click me
// </a>
//
// The above would send the event "event_name" to Google Analytics when the
// link is clicked.
//
// To enable debug mode for an element add the
// `data-analytics-debug-value="true"` attribute to it.

import { Controller } from "@hotwired/stimulus"
import gtag from "config/analytics"

export default class extends Controller {
  static values = {
    event: String,
    value: String,
    extra: String,
    debug: { type: Boolean, default: false },
  }

  publish(event) {
    if (this.shouldPublishToGoogle()) {
      const params = {
        ...({
          'send_to': this.measurementId,
          'transaction_id': this.pageVisitId,
        }),
        ...(this.valueValue && { value: this.valueValue }),
        ...(this.extraValue && { extra: this.extraValue }),
        ...(this.debugValue && { debug_mode: true }),
      }

      gtag('event', this.eventValue, params)
    }
  }

  get measurementId() { return this.metaTagContent('ga_measurement_id') }

  get pageVisitId() { return this.metaTagContent('page_visit_id') }

  shouldPublishToGoogle() {
    return this.measurementIdIsPresent() && this.googleAnalyticsIsLoaded()
  }

  measurementIdIsPresent() {
    return !!this.measurementId && this.measurementId.length > 0
  }

  googleAnalyticsIsLoaded() {
    return typeof gtag === 'function'
  }

  metaTagContent(name) {
    return document.querySelector(`meta[name="${name}"]`) &&
      document.querySelector(`meta[name="${name}"]`).content
  }
}
