// Adds the hidden class to an element when the dismiss event is called.
// Used for hiding elements when the user clicks eleswhere on the page, eg for
// dimissing pop ups.
// Usage:
// Attach
// `data-controller="dismissable"`
// `data-action="click@window->dismissable#dismiss"`
// `data-dismissable="ignore"`
// To the element you want to be dismissed when the user clicks elsewhere on
// the page.
// Attach `data-dismissable="ignore"` to other elements that should not cause
// this element to be dismissed (eg when working with content reveal).
// See the commit that introduced this controller for an example of it's
// useage.
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  dismiss(event) {
    if (!this.ignoreClick(event)) {
      this.element.classList.add("hidden")
    }
  }

  ignoreClick(event) {
    return !!event.target.closest('[data-dismissable="ignore"]')
  }
}
