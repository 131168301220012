// Keeps scroll position when prepending content to a scrollable container
import { Controller } from "@hotwired/stimulus"
import { useMutation } from 'stimulus-use'
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = [ "focus", "top" ]

  connect() {
    useMutation(this, { childList: true, subtree: true })
    useDispatch(this)

    this.focusElementHeightWas = this.focusTarget.offsetHeight

    // scroll to bottom of feed on page load
    this.element.scrollTop = this.element.scrollHeight
  }

  contentPrepended() {
    const changeInHeight = this.focusTarget.offsetHeight - this.focusElementHeightWas
    this.focusElementHeightWas = this.focusTarget.offsetHeight
    this.element.scrollTop = (this.focusTarget.offsetTop + changeInHeight)
  }

  mutate(entries) {
    let contentAppended = false;
    let contentPrepended = false;

    for (const mutation of entries) {
      if (mutation.type === 'childList' && mutation.target == this.focusTarget) {
        const addedNodes = Array.from(mutation.addedNodes)

        addedNodes.forEach(node => {
          if (!node.previousSibling) {
            contentPrepended = true;
          }
          if (!node.nextSibling) {
            contentAppended = true;
          }
        });
      }
    }

    if (contentPrepended) {
      this.contentPrepended()
    } else {
      // No need for special handling if content was appended
    }

    // Once we've handled setting the scroll position, focus on the target
    // element if we have one
    if (this.hasTopTarget) {
      this.scrollTo(this.topTarget)
    }

    this.dispatch("finished")
  }

  scrollTo(element) {
    this.element.scrollTop = element.offsetTop
  }
}
