import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["offering", "price", "priceFormatted", "description"]

  initialize() {
    this.setPrice = this.setPrice.bind(this)
  }

  connect() {
    this.setPrice()

    document.addEventListener("quote--currency-change:changed", this.setPrice)
  }

  disconnect() {
    document.removeEventListener("quote--currency-change:changed", this.setPrice)
  }

  focusPrice(event) {
    const el = event.currentTarget
    el.value = this.priceTarget.value
    el.type = "number"
  }

  blurPrice(event) {
    const el = event.currentTarget
    this.priceTarget.value = el.value
    el.type = "text"
    el.value = el.value == "" ? "" : this.formatAmount(el.value)
  }

  setPrice() {
    this.optionsInCurrentCurrency.forEach((o) => this.showOption(o))
    this.optionsNotInCurrentCurrency.forEach((o) => this.hideOption(o))

    const previouslySelectedOption = this.offeringTarget.selectedOptions[0]
    const newSelection = this.optionsInCurrentCurrency[0]

    previouslySelectedOption.selected = false
    newSelection.setAttribute("selected", "")

    this.setPriceTarget(newSelection)
    this.setDescriptionTarget(newSelection)
  }

  toggle(event) {
    const selectedOption = event.target.selectedOptions[0]
    this.setPriceTarget(selectedOption)
    this.setDescriptionTarget(selectedOption)
  }

  setPriceTarget(selectedOption) {
    const amount = JSON.parse(selectedOption.dataset.clientRate)

    if (this.hasPriceTarget) {
      this.priceTarget.value = amount
      this.priceFormattedTarget.value = this.formatAmount(amount)
    }
  }

  formatAmount(amount) {
    const currentCurrency = this.currentCurrency
    return (+amount).toLocaleString(undefined, {
      style: "currency",
      currency: currentCurrency.toUpperCase(),
    })
  }

  setDescriptionTarget(selectedOption) {
    this.descriptionTarget.value = selectedOption.dataset.description
  }

  showOption(option) {
    option.classList.remove("hidden")
    option.removeAttribute("hidden")
    option.removeAttribute("disabled")
  }

  hideOption(option) {
    option.classList.add("hidden")
    option.setAttribute("hidden", "")
    option.setAttribute("disabled", "")
  }

  get currentCurrency() {
    return document.getElementById("create_quote_form_currency").value
  }

  get optionsInCurrentCurrency() {
    return this.offeringOptions.filter((o) => o.dataset.currency == this.currentCurrency)
  }

  get optionsNotInCurrentCurrency() {
    return this.offeringOptions.filter((o) => o.dataset.currency != this.currentCurrency)
  }

  get offeringOptions() {
    return Array.from(this.offeringTarget.options)
  }
}
