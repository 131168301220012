import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  connect() {
    super.connect()
    const modalAutoOpenByParams = this.element.dataset.modalAutoOpenByParams
    this.originalContent = this.containerTarget.innerHTML

    if (modalAutoOpenByParams == "true") {
      this.handleModalAutoOpenByParams()
    }
  }

  open(event) {
    this.preventDefaultActionOpening = false
    super.open(event)
  }

  close(event) {
    super.close(event)
    // reset any turboframes in the modal to their original state
    this.containerTarget.innerHTML = this.originalContent
  }

  closeOnSuccess(event) {
    if (event.detail.success) {
      this.close(event)
    }
  }

  handleModalAutoOpenByParams() {
    const urlParams = new URLSearchParams(window.location.search)
    const targetOpenModal = urlParams.get("auto_open_modal")

    if (!targetOpenModal) {
      return
    }

    const autoOpenModal = document.querySelector(
      `[data-turbo-frame="${targetOpenModal}"]`
    )

    if (autoOpenModal) {
      autoOpenModal.click()
    }
  }
}
