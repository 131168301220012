/**
 * Update style outer div of some custom radio button
 *
 * Group your radio in one div
 * Add `data-controller="radio-container"` to the div
 * Add `data-radio-container-target="container"` to each container div of radio
 * Add `data-radio-container-target="radio"` to each radio
 *
 *
 * Example usage:
 *
 * ```
 * <div data-controller="radio-container">
 *  <div data-radio-container-target="container">
 *    <input type="radio" data-radio-container-target="radio">
 *  </div>
 *  <div data-radio-container-target="container">
 *    <input type="radio" data-radio-container-target="radio">
 *  </div>
 * </div>
 * ```
 **/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "radio"]
  static values = {
    checkedClass: { type: String, default: "border-blue-light" },
    uncheckedClass: { type: String, default: "border-gray-light" },
  }

  connect() {
    // Set initial container border color
    this.updateBorderColor()
  }

  initialize() {
    this.radioTargets.forEach((radio) => {
      radio.addEventListener("change", this.updateBorderColor.bind(this))
    })
  }

  updateBorderColor() {
    this.containerTargets.forEach((container) => {
      const isChecked = container.querySelector('input[type="radio"]').checked
      container.classList.toggle(this.checkedClassValue, isChecked)
      container.classList.toggle(this.uncheckedClassValue, !isChecked)
    })
  }
}
