import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (this.element.nodeName === "INPUT") {
      this.element.value = timeZone
    } else {
      this.element.innerText = timeZone
    }
  }
}
