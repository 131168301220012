import { Controller } from "@hotwired/stimulus"

function SubscribeException(message) {
  this.message = message
  this.name = "SubscribeException"
}

export default class extends Controller {
  static targets = ["card", "terms", "error", "form", "submitButton"]

  async connect() {
    const stripeMeta = document.querySelector('meta[name="stripe-key"]')
    const stripeKey = stripeMeta.getAttribute("content")

    this.clientSecret = this.data.get("clientSecret")

    this.stripe = Stripe(stripeKey)
    const elements = this.stripe.elements()

    this.card = elements.create("card")
    this.card.mount(this.cardTarget)

    this.formTarget.addEventListener("submit", this.submit.bind(this))
  }

  async submit(event) {
    event.preventDefault()

    this.submitButtonTarget.disabled = true
    this.errorTarget.textContent = ""

    try {
      await this.subscribe()

      this.formTarget.submit()
    } catch (e) {
      if (e.name !== "SubscribeException") throw e

      this.errorTarget.textContent = e.message
      this.submitButtonTarget.disabled = false
    }
  }

  async subscribe() {
    if (!this.termsTarget.checked) {
      throw new SubscribeException("Please agree to the Terms and Conditions")
    }

    const options = { payment_method: { card: this.card } }
    const { error } = await this.stripe.confirmCardPayment(this.clientSecret, options)

    if (error) {
      throw new SubscribeException(error.message)
    }
  }
}
