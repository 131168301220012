import { Controller } from "@hotwired/stimulus"
import { handleConfirm } from "../components/confirm_dialog"

export default class extends Controller {
  static values = {
    path: String,
  }

  visit(event) {
    // check if the element has a confirm dialog attached and let it execute first
    // this is required to be called directly as other wise the click does not
    // bubble correctly when attached to non-link/button elements
    let row = event.target.closest("[data-confirm]")
    if (row) {
      handleConfirm({ target: row })
      // we return here as the handleConfirm will automatically re-trigger the event on confirmation
      return
    }
    if (!this.hasPathValue) return

    Turbo.clearCache()
    // navigator.platform is Deprecated but navigator.userAgentData is not available
    // on all browsers
    const platform = (navigator.userAgentData || {}).platform || navigator.platform
    const isMac = !!platform.match(/^mac/i)
    if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
      // open in a new tab
      window.open(this.pathValue, "_blank").focus()
    } else {
      Turbo.visit(this.pathValue)
    }
  }
}
