import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    url: String,
  }

  redirectWithValue() {
    let url = new URL(this.urlValue)
    url.searchParams.append(this.element.name, this.element.value)
    Turbo.clearCache()
    Turbo.visit(url)
  }
}
