import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"
import { useIntersection } from "stimulus-use"

// Used for triggering actions on other controllers
// Example useage:
// ```
// <div
//  id="someId"
//  data-controller="events"
//  data-action="events:connected->some-other-controller#some-other-action"
//  >
// ```
// With the above snippet, someOtherControllerController#someOtherAction witll
// be triggered when the element with id "someId" is connected to the DOM
// NOTE the id attribute is not required for this to work.
//
export default class extends Controller {
  connect() {
    useDispatch(this)
    useIntersection(this)
    this.dispatch("connected")
  }

  appear() {
    this.dispatch("appeared")
  }
}
