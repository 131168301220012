import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["personal", "company"]

  showCompany() {
    const companySection = this.companyTarget
    const personalSection = this.personalTarget

    companySection.classList.remove("hidden")
    personalSection.classList.add("hidden")
  }

  showPersonal() {
    const companySection = this.companyTarget
    const personalSection = this.personalTarget

    companySection.classList.add("hidden")
    personalSection.classList.remove("hidden")
  }
}
