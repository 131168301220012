// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm

// Elements we want to listen to for data-confirm
const elements = [
  "a[data-confirm]",
  "button[data-confirm]",
  "input[type=submit][data-confirm]",
]

const createConfirmModal = (element) => {
  const id = "confirm-modal-" + String(Math.random()).slice(2, -1)
  const confirm = element.dataset.confirm
  // set the element which will container the confirm modal
  const modalPlacement = element.dataset.confirmModalPlacement
  const type = element.getAttribute("data-confirm-type") || "confirm"

  const content = `
    <dialog id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table text-left" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">

        <div class="bg-white mx-auto rounded shadow p-8 max-w-full lg:max-w-xl whitespace-normal">
          ${confirm}

          <div class="flex justify-start items-center flex-wrap mt-6">
            <button data-behavior="cancel" class="button-secondary mr-2 ${
              type == "info" ? `hidden` : ``
            }">Cancel</button>
            <button data-behavior="commit" class="danger focus:outline-none">Confirm</button>
          </div>
        </div>
      </div>
    </dialog>
  `

  let modal = null

  if (modalPlacement) {
    document.getElementById(modalPlacement).insertAdjacentHTML("afterend", content)
    modal = document.getElementById(modalPlacement).nextElementSibling
  } else {
    element.insertAdjacentHTML("afterend", content)
    modal = element.nextElementSibling
  }

  element.dataset.confirmModal = `#${id}`

  modal.addEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    }
  })

  modal.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
    event.preventDefault()
    element.removeAttribute("data-confirm-modal")
    modal.remove()
  })
  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => {
      return true
    }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute("data-confirm-modal")
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal
}

export const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(", "), "confirm", handleConfirm)
