import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dismissable"]

  dismiss() {
    const element = this.dismissableTarget
    element.classList.add("transform", "opacity-0", "transition", "duration-1000")
    setTimeout(() => this.element.remove(), 1000)
  }
}
