import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    storeKey: String,
    activeToggle: String,
  }

  get togglers() {
    return this.context.bindingObserver.bindings
      .filter((binding) => binding.action.methodName == "toggle")
      .map((binding) => binding.action.element)
  }

  get openedTogglers() {
    return this.togglers.filter((toggler) => this.isOpened(toggler))
  }

  get contents() {
    return this.scope.findAllElements("[data-accordion-id]")
  }

  get anchor() {
    return window.top.location.hash.substring(1)
  }

  connect() {
    this.enableTrans(false)

    this.store = new Store(this)

    if (this.anchor) {
      this.toggleAnchor(this.anchor)
    } else {
      this.init()
      this.store.load()
    }

    setTimeout(() => this.enableTrans(true), 200)
  }

  enableTrans(enabled) {
    this.contents.forEach((content) => {
      if (enabled) {
        content.classList.remove("accordion--disable-trans")
      } else {
        content.classList.add("accordion--disable-trans")
      }
    })
  }

  init() {
    this.togglers.forEach((toggler) => {
      let content = this.findContent(toggler)
      if (this.isOpened(toggler)) {
        this.show(toggler, content)
      } else {
        this.hide(toggler, content)
      }
    })
  }

  toggle(e) {
    this.togglers.forEach((toggler) => {
      if (toggler.contains(e.target)) {
        if (this.isOpened(toggler)) {
          this.close(toggler)
        } else {
          this.open(toggler)
        }
      }
    })

    e.preventDefault()
  }

  toggleAnchor(anchor) {
    this.togglers.forEach((toggler) => {
      let linkAnchor = toggler.href.substring(toggler.baseURI.indexOf("#") + 1)

      if (linkAnchor == anchor) {
        this.open(toggler)
      } else {
        this.close(toggler)
      }
    })
  }

  fallbackToFirstOpen() {
    let allClosed = this.togglers
      .map((toggler) => this.isOpened(toggler))
      .every((value) => value === false)
    if (allClosed) {
      this.open(this.togglers[0])
    }
  }

  open(toggler) {
    let content = this.findContent(toggler)
    this.show(toggler, content)
    this.store.save()
  }

  close(toggler) {
    let content = this.findContent(toggler)
    this.hide(toggler, content)
    this.store.save()
  }

  show(toggler, content) {
    this.toggleClass(toggler, content, true)
  }

  hide(toggler, content) {
    this.toggleClass(toggler, content, false)
  }

  isOpened(toggler) {
    return toggler.matches(".opened")
  }

  toggleClass(toggler, content, opened) {
    if (opened) {
      toggler.classList.add("opened")
      content.classList.remove("hidden")
    } else {
      toggler.classList.remove("opened")
      content.classList.add("hidden")
    }

    if (opened) {
      let openedText = toggler.getAttribute("data-accordion-opened-text-param")
      if (openedText) toggler.innerHTML = openedText
    } else {
      let closedText = toggler.getAttribute("data-accordion-closed-text-param")
      if (closedText) toggler.innerHTML = closedText
    }
  }

  findContent(toggler) {
    let id = this.getID(toggler)
    return this.scope.findElement(`[data-accordion-id="${id}"]`)
  }

  getID(toggler) {
    return toggler.getAttribute("href").replace(/^#/, "")
  }
}

class Store {
  constructor(controller) {
    this.controller = controller
  }

  get togglers() {
    return this.controller.togglers
  }

  get openedTogglers() {
    return this.controller.openedTogglers
  }

  get key() {
    return this.controller.storeKeyValue
  }

  load() {
    if (!this.key) return

    let ids = this.constructor.load(this.key)
    if (!ids) {
      this.controller.fallbackToFirstOpen()

      return
    }

    let idSet = new Set(ids)
    this.togglers.forEach((toggler) => {
      let content = this.controller.findContent(toggler)
      if (idSet.has(this.controller.getID(toggler))) {
        this.controller.show(toggler, content)
      } else {
        this.controller.hide(toggler, content)
      }
    })
  }

  save() {
    if (!this.key) return

    let ids = this.openedTogglers.map((toggler) => this.controller.getID(toggler))
    this.constructor.save(this.key, ids)
  }

  static load(key) {
    let json = sessionStorage.getItem(key)
    try {
      return JSON.parse(json)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static save(key, value) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(error)
      return null
    }
  }
}
