import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title"]

  print() {
    window.parent.document.title = `Lawbite - ${this.titleTarget.innerHTML}`
    window.print()
  }
}
