// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Tabs, Toggle } from "tailwindcss-stimulus-components"

const application = Application.start()
const context = require.context(
  "controllers",
  true,
  /^((?![\\/]administrate[\\/]).)*_controller\.js$/
)

application.register("tabs", Tabs)
application.register("toggle", Toggle)
application.load(definitionsFromContext(context))
