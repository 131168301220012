import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "terms", "error"]

  submit(event) {
    event.preventDefault()

    if (!this.termsTarget.checked) {
      this.errorTarget.textContent = "Please agree to the Terms and Conditions"
    } else {
      this.formTarget.submit()
    }
  }
}
