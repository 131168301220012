import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["cardField", "errorMessage", "paymentIntentId", "submit"]

  async connect() {
    if (this.hasCardFieldTarget) {
      const stripeKey = document
        .querySelector("meta[name='stripe-key']")
        .getAttribute("content")
      this.stripe = Stripe(stripeKey)
      this.installCardElement()
      this.disableForm()
    }

    if (this.data.get("payment-id")) {
      this.paymentId = this.data.get("payment-id")
      this.disableForm()

      const paymentStatus = await this.pollForStatusUpdate()

      if (paymentStatus == "paid") this.redirectToStripeComplete()
      else this.handleError()
    }
  }

  get total() {
    return parseInt(document.querySelector("#total").dataset.quoteTotal)
  }

  focusCardField() {
    this.card.focus()
  }

  async submitPayment(event) {
    event.preventDefault()

    await this.validatePromotions()
    await this.createStripePayment()
    await this.confirmPayment()
    const paymentStatus = await this.pollForStatusUpdate()

    if (paymentStatus == "paid") this.redirectToStripeComplete()
    else this.handleError()
  }

  async validatePromotions() {
    const _this = this

    this.disableForm()

    await fetch(`${_this.data.get("quote-promotions-path")}?total=${_this.total}`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json()
      })
      .then(async (json) => {
        if (json.errors.length) {
          this.setErrorMessage(json.errors)

          await fetch(_this.data.get("quote-invoice-path"), {
            method: "GET",
          })
            .then((response) => {
              return response.text()
            })
            .then((html) => {
              document.querySelector("#invoice").innerHTML = html
              _this.paymentIntent = undefined
              _this.enableForm()
              throw json.errors
            })
        }
      })
  }

  async confirmPayment() {
    const _this = this

    this.paymentInProgress = true

    const result = await this.stripe.confirmCardPayment(
      this.paymentIntent.client_secret,
      {
        payment_method: {
          card: this.card,
        },
      }
    )

    if (result.error) {
      await fetch(`/stripe_payments/${this.paymentId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        _this.paymentIntent = undefined
        _this.paymentId = undefined
        _this.setErrorMessage(result.error.message)
        _this.enableForm()
        _this.paymentInProgress = false
      })
    }
  }

  async createStripePayment() {
    if (this.paymentIntent == undefined) {
      await fetch("/stripe_payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_intent: {
            quote_id: this.data.get("quote-id"),
          },
          credit_payment: {
            amount_paid_by_credit: this.data.get("amount-paid-by-credit"),
          },
        }),
      }).then((result) => {
        return result.json().then((json) => {
          if (!result.ok) {
            this.setErrorMessage(json.message)
            this.enableForm()

            throw json
          }

          this.setErrorMessage("")
          this.paymentIntent = json.payment_intent
          this.paymentId = json.payment_id
          this.disableForm()
        })
      })
    }
  }

  installCardElement() {
    this.card = this.stripe
      .elements()
      .create("card", { style: { base: { fontSize: "16px" } } })
    this.card.mount(this.cardFieldTarget)
    this.card.on("change", this.updateForm.bind(this))
  }

  setErrorMessage(message) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = message
    }
  }

  updateForm(event) {
    if (this.hasSubmitTarget) {
      !event.complete ? this.disableForm() : this.enableForm()
    }

    this.setErrorMessage(event.error ? event.error.message : "")
  }

  creditsError(json) {
    let message = Object.entries(json.errors).reduce((string, [attribute, errors]) => {
      return string + `<li>${attribute}: ` + errors.join(", ") + "</li>"
    }, "Credits Error! <ul class=detial>")

    return message + "</ul>"
  }

  async pollForStatusUpdate() {
    let status = await this.getStatusUpdate()

    while (status == "pending" && this.paymentInProgress) {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      status = await this.getStatusUpdate()
    }

    this.paymentInProgress = false

    return status
  }

  async getStatusUpdate() {
    const _this = this

    const status = await new Promise((resolve) =>
      fetch(`/payments/${this.paymentId}`, {
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            resolve(json.status)
          })
        } else {
          _this.paymentIntent = undefined
          _this.handleError()
        }
      })
    )

    return status
  }

  redirectToStripeComplete() {
    const url = this.data
      .get("complete-path")
      .replace("/payment_id", `/${this.paymentId}/`)
    window.location = url
  }

  disableForm() {
    this.submitTarget.disabled = true
    if (this.paymentId) {
      this.submitTarget.value = this.submitTarget.dataset.inProgressDisableWith
      this.card.update({ disabled: true })
    }
  }

  enableForm() {
    this.submitTarget.disabled = false
    this.submitTarget.value = this.submitTarget.dataset.enableWith
    this.card.update({ disabled: false })
  }

  handleError() {
    this.enableForm()
  }
}
