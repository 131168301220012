import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {
  constructor(args) {
    super(args)

    this.participants = document.querySelector("#case-participants-sidebar")
  }

  openValueChanged() {
    if (this.openValue) {
      if (this.participants) {
        this.participants.classList.add("overflow-y-hidden")
      }
      this._show()
    } else {
      if (this.participants) {
        this.participants.classList.remove("overflow-y-hidden")
      }
      this._hide()
    }
  }
}
