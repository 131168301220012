import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { DirectUpload } from "@rails/activestorage"

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

function toArray(value) {
  if (Array.isArray(value)) {
    return value
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export default class extends Controller {
  static targets = ["input", "clickable", "preview"]

  static values = {
    existingFiles: Array,
  }

  connect() {
    // needed to get dropzone to work within a modal
    if (Dropzone.instances.length > 0) {
      Dropzone.instances.forEach((dzInstance) => dzInstance.destroy())
    }
    // Set where dropzone will add previews
    if (this.hasPreviewTarget) {
      this.previewsContainer = this.previewTarget
    } else {
      this.previewsContainer = this.element
    }
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
    this.displayExistingFiles()
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
  }

  disconnect() {
    this.dropZone.destroy()
  }

  // Private

  // See: https://github.com/dropzone/dropzone/discussions/1909
  displayExistingFiles() {
    this.existingFilesValue.forEach((existingFile) => {
      console.log("existingFile ", existingFile)
      const mockFile = existingFile
      const resizeThumbnail = existingFile.isImage

      const image = new Image()
      image.src = existingFile.url

      // Only display the file if a preview can be loaded
      image.onload = () => {
        this.dropZone.displayExistingFile(
          mockFile,
          existingFile.url,
          null,
          null,
          resizeThumbnail
        )
      }
      // else load the file without a preview
      image.onerror = () => {
        // add to files array; not sure if it's needed or not
        // this.dropZone.files.push(mockFile)
        this.dropZone.emit("addedfile", mockFile)
        this.dropZone.emit("complete", mockFile)
      }

      mockFile.controller = createDirectUploadController(this, mockFile)
      mockFile.controller.hiddenInput = mockFile.controller.createHiddenInput()
      mockFile.controller.hiddenInput.value = existingFile.signed_id
    })
  }

  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    // necessary to get Dropzone working in a modal
    this.dropZone.on("error", function (file, message) {
      alert(message)
      this.removeFile(file)
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 5
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(document.body, {
    previewsContainer: controller.previewsContainer,
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    clickable: [controller.element],
  })
}
