import { Controller } from "@hotwired/stimulus"

// We can check each input before we validate the submitButton
// @target submitButton [Submit]
// @target form [Form]
export default class extends Controller {
  static targets = ["submitButton", "form"]

  connect() {
    this.disableSubmission()
  }

  disableSubmission() {
    this.submitButtonTarget.disabled = !this.isFormValid()
  }

  isFormValid() {
    return (
      this.requiredCheckBoxesAreChecked() &&
      this.requiredInputsAreFilled() &&
      this.requiredRadioButtonsAreChecked() &&
      this.requiredEmailsAreFilled()
    )
  }

  get requiredElements() {
    return Array.from(this.formTarget.elements).filter((e) => e.required)
  }

  requiredInputsAreFilled() {
    return this.requiredElements
      .filter((e) => e.type != "checkbox" && e.type != "radio" && e.type != "email")
      .every((e) => e.value.length != 0)
  }

  requiredEmailsAreFilled() {
    return this.requiredElements
      .filter((e) => e.type == "email")
      .every((e) => e.validity.valid)
  }

  requiredCheckBoxesAreChecked() {
    return this.requiredElements
      .filter((e) => e.type == "checkbox")
      .every((e) => e.checked)
  }

  // For each group of required radio buttons make sure at least one is checked
  requiredRadioButtonsAreChecked() {
    const radios = this.requiredElements.filter((e) => e.type == "radio")

    const radiosGroupedByName = radios.reduce((groups, button) => {
      const group = groups[button.name] || []
      group.push(button)
      groups[button.name] = group
      return groups
    }, {})

    const groups = Object.values(radiosGroupedByName)

    return groups.every((group) => group.some((radio) => radio.checked))
  }
}
