import { Dropdown } from "tailwindcss-stimulus-components"
import Rails from "@rails/ujs"

// we need to declare this outside the scope of the class so it can
// act as a global and allow us to lock functionality on groups of
// dropdown elements
let groupLock = {}

export default class extends Dropdown {
  static targets = ["menu", "button", "active"]
  static values = { url: String }

  connect() {
    // optionally load menu content in from URL endpoint
    if (this.urlValue) this.loadContent()
    let elem = this.element.closest("[data-dropdown-group]")
    if (elem) {
      this.groupId = elem.getAttribute("data-dropdown-group")
    } else {
      this.groupId = Math.random().toString(36)
    }
    this.instanceId = Math.random().toString(36)

    this.hideOnlyClickToggleBtn =
      this.element.dataset.dropdownHideOnlyClickToggleBtn || false

    // we need to unlock here to avoid issue with page reloads via turbo.
    this.unlock()
    super.connect()
  }

  // Allow dropdown to work with hover on the top element
  // This require override to some of the base toggle/show functionality to trigger at the correct times)
  mouseenter(event) {
    if (!this.locked()) {
      this.show(event)
    }
  }

  mouseleave(event) {
    if (this.element === event.target && this.openValue && !this.locked()) {
      this.openValue = false
    }
  }

  // three states are possible when entering this function
  // * open but not locked - item have been hovered and we are just clicking it
  // * open and locked - item has been clicked so we are unclicking it
  // * not open and not locked - a different dropdown is locked when we click
  //   in this case we steal the lock and then toggle the view
  toggle() {
    if (this.hideOnlyClickToggleBtn) {
      // In hideOnlyClickToggleBtn mode
      // We don't want to trigger toggle when click outside the toggle button
      if (!this.isEventInsideToggleBtn(event)) {
        return
      }
    }

    if (this.locked(true) || !this.openValue) {
      this.openValue || this.lock()
      super.toggle()
    } else {
      this.lock()
    }
  }

  // openValueChanged() {
  //   if (this.openValue) {
  //     this._show()
  //   } else {
  //     this._hide()
  //   }
  // }

  // we need to override this private function to ensure we unlock the group
  _hide(cb) {
    if (this.hideOnlyClickToggleBtn) {
      // Check if the click is on the toggle button
      // If not, do not hide the dropdown
      if (!this.isEventInsideToggleBtn(event)) {
        return
      }
    }

    this.unlock()
    super._hide(cb)
  }

  isEventInsideToggleBtn(event) {
    const rect = this.buttonTarget.getBoundingClientRect()

    const x = event.clientX
    const y = event.clientY

    return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
  }

  //
  // _show(cb) {
  //   super._show(cb)
  // }

  locked(fully = false) {
    if (fully) {
      return !!this.groupId && groupLock[this.groupId] == this.instanceId
    } else {
      return !!this.groupId && !!groupLock[this.groupId]
    }
  }

  lock() {
    groupLock[this.groupId] = this.instanceId
  }

  unlock() {
    // only unlock the if it is locked by this drop down instance
    // this heap avoid race conditions when switching dropdown item in a group
    this.locked(true) && (groupLock[this.groupId] = null)
  }

  async loadContent() {
    const _this = this
    await fetch(this.urlValue, {
      method: "GET",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "text/html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        _this.menuTarget.innerHTML = html
        _this.toggle()
      })
  }
}
