import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

// Controller to click a link as soon as it becomes visible.
// Useful for automatically fetching the next page of results when implementing
// infinite scroll.
// Example usage:
// ```
// <a href="/next_page" data-controller="auto-pagination"></a>
// ```
// The above link will be clicked as soon as it becomes visible.
export default class extends Controller {
  static values = {
    delay: Number,
  }

  connect() {
    useIntersection(this)
  }

  appear() {
    let _this = this
    setTimeout(function () {
      _this.element.click()
    }, this.delayValue)
  }
}
