import { closeHelpMenu, changeHelpMenuBehavior } from "helpers/help-helper"

// https://legacydocs.hubspot.com/docs/methods/conversations_api/hubspot-conversations-javascript-api
export const openChat = () => {
  window["HubSpotConversations"]?.widget.open()
}

export const closeChat = () => {
  window["HubSpotConversations"]?.widget.close()
}

export const loadChat = () => {
  window["HubSpotConversations"]?.widget.load({ widgetOpen: false })
}

export const refreshChat = () => {
  window["HubSpotConversations"]?.widget.refresh()
}

export const removeChat = () => {
  window["HubSpotConversations"]?.widget.remove()
}

export const showChat = (doc) => {
  doc.style.setProperty("display", "block", "important")
}

export const hideChat = (doc) => {
  doc.style.setProperty("display", "none", "important")
}

export const setupChat = () => {
  // same delay loading time the chat until the page is loaded declared in hubspot settings
  const DELAY = 0
  const chatContainer = document.querySelector("#hubspot-chat-container")

  closeHelpMenu()

  const init = () => {
    if (chatContainer) {
      showChat(chatContainer)

      changeHelpMenuBehavior("X")

      const helpMenuButton = document.querySelector(".help-menu__button")
      if (helpMenuButton) {
        helpMenuButton.addEventListener("click", (event) => {
          event.preventDefault()

          hideChat(chatContainer)
          changeHelpMenuBehavior("?")
        })
      }
    }
  }

  setTimeout(() => {
    if (window.HubSpotConversations) {
      init()
    } else {
      window.hsConversationsOnReady = [
        () => {
          init()
        },
      ]
    }
  }, DELAY)
}
