import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cardField", "errorMessage", "submit", "paymentIntentId"]
  static values = {
    secret: String,
  }

  connect() {
    if (this.hasCardFieldTarget) {
      const stripeKey = document
        .querySelector("meta[name='stripe-key']")
        .getAttribute("content")
      this.stripe = Stripe(stripeKey)
      this.installCardElement()
      this.submitTarget.disabled = true
    }
  }

  focusCardField() {
    this.card.focus()
  }

  async submitPayment(event) {
    event.preventDefault()

    await this.confirmPayment()

    if (this.paymentIntentIdTarget.value) {
      this.element.submit()
    }
  }

  async confirmPayment() {
    const result = await this.stripe.confirmCardPayment(this.secretValue, {
      payment_method: {
        card: this.card,
      },
    })

    if (result.error) {
      this.setErrorMessage(result.error.message)
    } else {
      this.setPaymentIntentIdValue(result.paymentIntent.id)
    }
  }

  installCardElement() {
    this.card = this.stripe
      .elements()
      .create("card", { style: { base: { fontSize: "16px" } } })
    this.card.mount(this.cardFieldTarget)
    this.card.on("change", this.updateForm.bind(this))
  }

  setErrorMessage(message) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = message
    }
  }

  setPaymentIntentIdValue(value) {
    this.paymentIntentIdTarget.value = value
  }

  updateForm(event) {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !event.complete
    }

    this.setErrorMessage(event.error ? event.error.message : "")
  }
}
