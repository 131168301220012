import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["user", "form"]
  static values = { number: String, name: String }

  submit(e) {
    if (this.userTarget.selectedIndex > 0) {
      e.preventDefault()

      let selectedUser = this.userTarget.options[this.userTarget.selectedIndex].text
      if (
        confirm(
          "Assigning user " +
            selectedUser +
            " to case " +
            this.nameValue +
            " (" +
            this.numberValue +
            ")"
        )
      ) {
        this.formTarget.submit()
      }
    }
  }
}
