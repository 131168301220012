import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    const download = new URLSearchParams(window.location.search).get("download")

    if (download === "true") {
      window.location = this.buttonTarget.href
    }
  }
}
