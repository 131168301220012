import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  connect() {
    this.config = {
      dateFormat: "d/m/Y",
      minDate: "today",
      maxDate: new Date(2100, 1, 1),
      inline: false,
    }

    super.connect()
  }
}
