import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copyFields(event) {
    event.preventDefault()

    Array.from(document.querySelectorAll("[data-copy-field-from]")).forEach((input) => {
      let copiedField = document.querySelector(input.dataset.copyFieldFrom)

      if (copiedField) {
        let copiedValue = copiedField.value

        if (!!copiedValue && copiedValue !== "") {
          input.value = copiedValue
        }
      }
    })
  }
}
