import BaseController from "../base_controller"

export default class extends BaseController {
  static targets = ["state", "category"]

  preventEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.search()
    }
  }

  search() {
    this.state = "all"
    this.category = ""
    this.submit()
  }

  set state(value) {
    this.stateTarget.value = value
  }

  set category(value) {
    this.categoryTarget.value = value
  }
}
