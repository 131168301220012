// Tagify an input element - https://github.com/yairEO/tagify
// Usage:
//  ```
//    <input data-controller="tagify"/>
//  ```
//
import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

export default class extends Controller {
  connect() {
    this.tagify = new Tagify(
      this.element,
    )
  }
}
