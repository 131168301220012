import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  select(event) {
    event.preventDefault()

    const selectedOption = event.currentTarget.value

    this.itemTargets
      .filter((target) =>
        this.isMatch(target.dataset.enableOnSelectedOption, selectedOption)
      )
      .forEach((target) => (target.disabled = false))

    this.itemTargets
      .filter(
        (target) => !this.isMatch(target.dataset.enableOnSelectedOption, selectedOption)
      )
      .forEach((target) => (target.disabled = true))
  }

  isMatch(candidate, target) {
    let candidates = [this.parsePotentialJson(candidate)].flat()

    return candidates.includes(target)
  }

  // Support both "regular" strings and JSON encoded arrays for
  // data-show-on-selected-option attributes
  parsePotentialJson(valueOrJson) {
    try {
      return JSON.parse(valueOrJson)
    } catch (error) {
      return valueOrJson
    }
  }
}
