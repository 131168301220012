import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["titleField"]

  connect() {
    this.completionMilestoneTitle = "Completion"
    this.cachedTitleFieldValue = this.titleFieldTarget.value
  }

  toggleCompletionMilestone(event) {
    if (event.target.checked) {
      this.titleFieldTarget.disabled = true
      this.titleFieldTarget.classList.add("opacity-40")
      this.cachedTitleFieldValue = this.titleFieldTarget.value
      this.titleFieldTarget.value = this.completionMilestoneTitle
    } else {
      this.titleFieldTarget.disabled = false
      this.titleFieldTarget.classList.remove("opacity-40")

      if (this.cachedTitleFieldValue !== this.completionMilestoneTitle) {
        this.titleFieldTarget.value = this.cachedTitleFieldValue
      } else {
        this.titleFieldTarget.value = ""
      }
    }
  }
}
