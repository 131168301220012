import BaseController from "../base_controller"

export default class extends BaseController {
  static targets = ["state"]

  preventEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.search()
    }
  }

  search() {
    this.state = "all"
    this.submit()
  }

  set state(value) {
    this.stateTarget.value = value
  }
}
