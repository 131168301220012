import BaseController from "./base_controller"

export default class extends BaseController {
  static targets = ["form", "unreadSwitch", "submitBtn"]

  submit() {
    this.submitBtnTarget.click()
  }

  search() {
    const _this = this

    if (this.searchInput.length === 0 || this.searchInput.length >= 3) {
      window.clearTimeout(this.timeout)

      this.timeout = window.setTimeout(() => {
        _this.submit()
      }, 1500)
    }
  }
}
