import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["destination"]

  toggle() {
    const contentData = event.target.dataset.contentToggleData

    if (contentData) {
      this.destinationTarget.innerHTML = contentData
    } else {
      this.destinationTarget.innerHTML = ""
    }
  }
}
