import { Controller } from "@hotwired/stimulus"
import { convertToBoolean } from "../helpers/method-helper"
import tippy, { followCursor } from "tippy.js"
import "tippy.js/themes/translucent.css"

export default class extends Controller {
  static values = {
    followCursor: String,
    interactive: Boolean,
    placement: String,
    theme: String,
  }

  static targets = ["content"]

  initialize() {
    this.tooltipArrow = this.element.dataset.tooltipArrow || true
    this.tooltipHideOnEmpty = this.element.dataset.tooltipHideOnEmpty || false
  }

  connect() {
    const options = {
      allowHTML: true,
      arrow: convertToBoolean(this.tooltipArrow),
      appendTo: () => document.body,
      content: this.content,
      interactive: this.interactive,
      followCursor: this.followCursor,
      placement: this.placement,
      plugins: [followCursor],
      theme: this.theme,
    }

    if (this.tooltipHideOnEmpty && this.isEmptyContent()) {
      return
    }

    this.tippy = tippy(this.element, options)
  }

  disconnect() {
    this.tippy.destroy()
  }

  isEmptyContent() {
    const emptyList = ["", "-", undefined, null]

    const tippyContent = this.element.dataset.tippyContent
    return emptyList.includes(tippyContent)
  }

  // FIXME RL: when we upgrade to stimulus 3 replace these with the new default
  // option for values
  get content() {
    if (this.hasContentTarget) {
      return this.contentTarget.innerHTML
    } else {
      return ""
    }
  }

  get followCursor() {
    if (this.hasFollowCursorValue) {
      return this.followCursorValue
    } else {
      return "default"
    }
  }

  get interactive() {
    if (this.hasInteractiveValue) {
      return this.interactiveValue
    } else {
      return false
    }
  }

  get placement() {
    if (this.hasPlacementValue) {
      return this.placementValue
    } else {
      return "top"
    }
  }

  get theme() {
    if (this.hasThemeValue) {
      this.themeValue
    } else {
      return ""
    }
  }
}
