import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["day", "timeline", "slot"]
  // @todo: Add date value and check this instead of finding slot through target

  connect() {
    this.loadDayIndicator()

    // Contain position of the day indicator
    window.onresize = () => {
      this.loadDayIndicator()
    }
  }

  loadDayIndicator() {
    if (!this.isCurrentActiveSlot()) {
      return
    }

    // Show day indicator
    this.dayTarget.classList.remove("hidden")

    // Re-size day indicator under timeline
    const timelineHeight = this.timelineTarget.offsetHeight
    const lineHeight = this._height - timelineHeight
    const marginOffSet = 16 // mb-4: 1rem; /* 16px */

    this.dayTarget.style.top = timelineHeight - marginOffSet + "px"
    this.dayTarget.style.height = lineHeight + marginOffSet + "px"

    // Re-position day indicator on correct timestamp
    const xPosition = this.slotTarget.offsetLeft

    this.dayTarget.style.left = xPosition + "px"
  }

  isCurrentActiveSlot() {
    return this.hasDayTarget && this.hasTimelineTarget && this.hasSlotTarget
  }

  get _height() {
    return this.element.offsetHeight
  }
}
