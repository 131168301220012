import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["passwordField", "showIcon", "hideIcon"]

  toggle(event) {
    event.preventDefault()

    if (this.passwordFieldTarget.type == "password") {
      this.passwordFieldTarget.type = "text"
      this.showIconTarget.classList.toggle("hidden")
      this.hideIconTarget.classList.toggle("hidden")
    } else {
      this.passwordFieldTarget.type = "password"
      this.showIconTarget.classList.toggle("hidden")
      this.hideIconTarget.classList.toggle("hidden")
    }
  }
}
