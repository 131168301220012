import { Toggle } from "tailwindcss-stimulus-components"
import { animateHide } from "helpers/animation-helper"

export default class extends Toggle {
  hide(event) {
    event.preventDefault()
    const hideAnimation = (this.toggleClass =
      this.data.get("hide-animation") || "anim-fade-out")

    this.toggleableTargets.forEach((target) => {
      animateHide(target, hideAnimation).then(() => {
        this.openValue = false
      })
    })
  }
}
