import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  submit() {
    this.formTarget.requestSubmit()
    if (this.formTarget.getAttribute("data-turbo-frame")) {
      // TODO: remove this as unneeded once we upgrade turbo version
      let formData = new FormData(this.formTarget)
      let search = new URLSearchParams(formData)
      let queryString = search.toString()
      let url = this.formTarget.action + "?" + queryString
      window.history.pushState({ path: url }, "", url)
    }
  }

  preventEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }
}
