import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = ["field"]
  static values = {
    caseFileId: String,
    internal: Boolean,
  }
  static debounces = ["fetchUsers"]

  connect() {
    useDebounce(this, { wait: 300 })
    this.editor = this.fieldTarget.editor
    this.caseFileId = this.caseFileIdValue
    this.internal = this.internalValue
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "name",
      menuItemLimit: 10,
      menuShowMinLength: 2,
      menuContainer: document.querySelector(".trix-editor"),
      containerClass: "mention-container",
      menuItemTemplate: function (item) {
        return item.original.menu_item
      },
      values: this.fetchUsers.bind(this),
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(input, callback) {
    fetch(
      `/mentions.json?` +
        new URLSearchParams({
          case_file_id: this.caseFileId,
          internal: this.internal,
          search: input,
        })
    )
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((error) => callback([]))
  }

  replaced(event) {
    let mention = event.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    let tributeLength = endPos - startPos
    let trixStartPos = position - tributeLength
    let trixEndPos = position

    this.editor.setSelectedRange([trixStartPos, trixEndPos])
    this.editor.deleteInDirection("backward")
  }
}
