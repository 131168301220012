// Used for generating nested fields for accepts_nested_attributes_for like
// associations
// Usage:
// ```
// <%= form_with(model: post, data: { controller: "nested-form" }) do |f| %>
//   <%= f.label :title %>
//   <%= f.text_field :title %>
//
//   <%= f.label :body %>
//   <%= f.text_area :body %>
//
//   <%# fields for any existing comments %>
//   <%= f.fields_for(:comments, post.comments) do |ff| %>
//     <div class="nested-form-wrapper">
//       <%= ff.label :body %>
//       <%= ff.text_field :body %>
//       <%= ff.hidden_field :_destroy %>
//       <%= link_to "Delete comment", "#", data: { action: "nested-form#remove" } %>
//     </div>
//   <% end %>
//
//   <template data-nested-form-target="template">
//     <%= f.fields_for(:comments, post.comments.new, child_index: "NEW_RECORD") do |ff| %>
//       <div class="nested-form-wrapper">
//         <%= ff.label :body %>
//         <%= ff.text_field :body %>
//         <%= ff.hidden_field :_destroy %>
//         <%= link_to "Delete comment", "#", data: { action: "nested-form#remove" } %>
//       </div>
//     <% end %>
//   </template>
//
//   <div data-nested-form-target="target"></div>
//
//   <%= link_to "Add comment", "#", data: { action: "nested-form#add" } %>
//
//   <%= f.submit %>
// <% end %>
// ```

import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = ["target", "template"]

  connect() {
    useDispatch(this)

    this.wrapperSelector = this.data.get("wrapperSelector") || ".nested-form-wrapper"
  }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    )
    this.targetTarget.insertAdjacentHTML("beforebegin", content)

    window.setTimeout(() => {
      this.dispatch("added")
    })
  }

  remove(e) {
    e.preventDefault()

    const wrapper = e.target.closest(this.wrapperSelector)

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = "none"
      wrapper.querySelector("input[name*='_destroy']").value = 1
    }
  }
}
