import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    const element = this.containerTarget
    element.scrollTop = 0
  }

  // Scroll to an element that triggered this action
  // Example usage
  // ```
  // <section data-controller="content-scroll">
  //  <div class="overflow-y-auto" data-content-scroll-target="container">
  //    <a id="some-link" data-action="content-scroll#scrollTo">
  // ```
  // When `#some-link`is clicked it will be scrolled to the top of the
  // container.
  scrollTo(event) {
    const container = this.containerTarget
    container.scrollTop = event.target.offsetTop
  }

  scrollBottom() {
    const container = this.containerTarget
    container.scrollTop = container.scrollHeight
  }
}
