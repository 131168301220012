export const focusFirstAutofocusableElement = (target) => {
  const autofocusedElement = target.querySelector("[autofocus]")
  if (autofocusedElement) {
    if (autofocusedElement.value && autofocusedElement.setSelectionRange)
      autofocusedElement.setSelectionRange(
        autofocusedElement.value.length,
        autofocusedElement.value.length
      )
    setTimeout(() => {
      autofocusedElement.focus()
    })
  }
}
