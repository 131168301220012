import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = ["destination"]

  connect() {
    useDispatch(this)

    if (this.hasDestinationTarget) {
      if (this.data.get("url")) {
        this.loadContent(this.data.get("url"))
      }
    }
  }

  load(event) {
    event.preventDefault()
    const url = event.target.dataset.contentLoaderUrl
    const absoluteUrl = new URL(`http://example.com${url}`)
    const params = new URLSearchParams(absoluteUrl.search.slice(0))

    const shouldPassValue = event.target.dataset.contentLoaderPassValue
    const actionValue = event.target.value

    if (url) {
      if (shouldPassValue) {
        params.append("value", actionValue)

        if (event.target.dataset.contentLoaderTargetLabel) {
          params.append("label", event.target.dataset.contentLoaderTargetLabel)
        }

        const path = url.split("?")[0]
        this.loadContent(`${path}?${params.toString()}`)
      } else {
        this.loadContent(url)
      }
    }
  }

  loadContent(url) {
    fetch(url)
      .then((response) => {
        return response.text()
      })
      .then((text) => {
        this.destinationTarget.innerHTML = text
        window.setTimeout(() => {
          this.dispatch("loaded")
        })
      })
  }
}
