import { Modal } from "tailwindcss-stimulus-components"
import Rails from "@rails/ujs"

import { focusFirstAutofocusableElement } from "helpers/focus-helper"

export default class extends Modal {
  initialize() {
    super.initialize()
    this.startVisible = this.element.dataset.visible
    this.populate = this.populate.bind(this)
    this.beforeTurboRequest = this.beforeTurboRequest.bind(this)
  }

  connect() {
    super.connect()
    this.show()

    this.element.addEventListener("turbo:submit-start", this.beforeTurboRequest)
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.beforeTurboRequest)
    super.disconnect()
  }

  show() {
    if (this.startVisible) {
      this.containerTarget.classList.remove("hidden")

      focusFirstAutofocusableElement(this.containerTarget)

      document.body.insertAdjacentHTML("beforeend", this._backgroundHTML())
      this.background = document.querySelector("#modal-background")
    }
  }

  open(event) {
    event.preventDefault()

    const url = event.currentTarget.href
    if (!url) {
      // modal is inlined, just open it
      super.open(event)
      return
    }

    this.openerElement = event.currentTarget

    Rails.ajax({
      type: "get",
      url: url,
      dataType: "html",
      beforeSend: (xhr, options) => {
        xhr.setRequestHeader("X-Show-In-Modal", true)
        return true
      },
      success: (data) => {
        if (this.hasContainerTarget) {
          this.containerTarget.replaceWith(data.body.firstChild)
        } else {
          this.element.appendChild(data.body.firstChild)
        }

        focusFirstAutofocusableElement(this.containerTarget)

        super.open(event)
      },
      error: (data) => {
        console.log("Error ", data)
      },
    })
  }

  beforeTurboRequest(event) {
    const {
      detail: { formSubmission },
    } = event
    formSubmission.fetchRequest.headers["X-Show-In-Modal"] = true

    document.addEventListener("turbo:before-fetch-response", this.populate)
  }

  close(event) {
    if (this.hasContainerTarget) {
      super.close(event)
      if (this.openerElement) this.openerElement.focus()
    }
  }

  async populate(event) {
    document.removeEventListener("turbo:before-fetch-response", this.populate)

    const {
      detail: { fetchResponse },
    } = event

    if (!fetchResponse.succeeded) {
      event.preventDefault()
      this.containerTarget.outerHTML = await fetchResponse.responseText

      this.containerTarget.classList.remove("hidden", "animated", "anim-fade-in")
      focusFirstAutofocusableElement(this.containerTarget)
    }
  }

  _backgroundHTML() {
    return '<div id="modal-background" class="animated anim-fade-in"></div>'
  }
}
