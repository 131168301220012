import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { field: String }
  static targets = ["loading", "main"]

  set(event) {
    let value = event.target.dataset.urlSettingPeriod
    let newRootUrl = this.updateUrl(window.location, value)

    if (newRootUrl !== window.location.href) {
      window.history.pushState({ path: newRootUrl }, "", newRootUrl)
      if (this.hasMainTarget) this.mainTarget.classList.add("hidden")
      if (this.hasLoadingTarget) this.loadingTarget.classList.remove("hidden")
    }
  }

  updateUrl(url, value) {
    const paramsString = url.search
    const searchParams = new URLSearchParams(paramsString)
    if (value) {
      searchParams.set(this.fieldValue, value)
    } else {
      searchParams.delete(this.fieldValue)
    }
    return url.origin + url.pathname + "?" + searchParams.toString() + url.hash
  }
}
