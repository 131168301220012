import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  connect() {
    useDispatch(this)
  }

  action(event) {
    this.element.submit()
  }

  async submitAndDoNothing(event) {
    this._submitWithoutTurbo(event)
  }

  // When making concurrent form submissions, Turbo will cancel the previous
  // requests, such that only the last request will be processed.
  // See https://github.com/hotwired/turbo-rails/issues/310 for details
  async _submitWithoutTurbo(event) {
    const form = this.element

    const formData = new FormData(form)

    const urlEncodedData = new URLSearchParams(formData).toString();

    const csrfToken = document.head.querySelector("meta[name=csrf-token]")?.content

    const response = await fetch(form.action, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlEncodedData,
      credentials: 'include',
    })

    if (!response.ok) { throw new Error(`Error: ${response.statusText}`); }

    this.dispatch("submitted")

    return response
  }
}
