import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["nav", "entries"]

  loadMore() {
    if (this._nextLink && this._bottom) {
      Rails.ajax({
        type: "GET",
        url: this._nextLink.href,
        success: (data) => {
          this.navTarget.innerHTML = data.pagy
          this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
        },
      })
    }
  }

  get _bottom() {
    return this.element.offsetHeight + this.element.scrollTop >= this.element.scrollHeight
  }

  get _nextLink() {
    return this.navTarget.querySelector(".next a")
  }
}
