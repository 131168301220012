import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "paymentFields", "textarea", "input"]

  connect() {
    this.checkboxTarget.addEventListener("change", this.handleChange)
    this.handleChange()
  }

  handleChange = () => {
    const checked = this.checkboxTarget.checked
    if (checked) {
      this.paymentFieldsTarget.style.display = "none"
      this.inputTarget.style.display = "block"
      this.textareaTarget.style.display = "block"
    } else {
      this.paymentFieldsTarget.style.display = "block"
      this.inputTarget.style.display = "none"
      this.textareaTarget.style.display = "none"
    }
  }
}
