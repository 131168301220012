export const closeHelpMenu = () => {
  const helpMenuList = document.querySelector(".help-menu__items")
  if (helpMenuList) {
    helpMenuList.classList.add("hidden")
  }
}

export const changeHelpMenuBehavior = (text = "?") => {
  const helpMenuButton = document.querySelector(".help-menu__button")
  if (helpMenuButton) {
    helpMenuButton.innerHTML = text
  }
}

export const removeHelperMenu = () => {
  const helpMenu = document.querySelector(".help-menu")
  if (helpMenu) {
    helpMenu.remove()
  }
}
