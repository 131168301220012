import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchInput"]

  initialize() {
    if (this.hasSearchInputTarget && this.searchInput.length) {
      const val = this.searchInputTarget.value
      this.searchInputTarget.value = ""
      this.searchInputTarget.value = val
      this.searchInputTarget.focus()
    }
  }

  submit() {
    this.element.submit()
  }

  preventEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  search() {
    if (this.searchInput.length === 0 || this.searchInput.length >= 1) {
      window.clearTimeout(this.timeout)

      this.timeout = window.setTimeout(() => {
        this.submit()
      }, 1500)
    }
  }

  get searchInput() {
    return this.searchInputTarget.value.trim()
  }
}
