import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// global to hold off the timer
let timer

function debounce(func, timeout = 300) {
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

function fetchData(element, search, callback) {
  fetch(
    element.getAttribute("data-slim-select-remote-url") +
      "?search=" +
      encodeURIComponent(search)
  )
    .then(function (response) {
      return response.json()
    })
    .then(function (json) {
      let data = []
      for (let i = 0; i < json.length; i++) {
        data.push({
          text: json[i].value,
          value: json[i].id,
        })
      }

      callback(data)
    })
    .catch(function (error) {
      // If any errors happened send false back through the callback
      callback(false)
    })
}
export default class extends Controller {
  static values = {
    allowDeselect: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
  }

  disconnect() {
    if (this.widget) this.widget.destroy()
  }

  connect() {
    const element = this.element

    const options = {
      select: element,
      allowDeselect: this.allowDeselectValue,
      placeholder: this.data.get("placeholder"),
      showSearch: this.showSearchValue,
    }

    if (this.data.has("remote-url")) {
      options.ajax = function (search, callback) {
        if (search.length < 1) {
          return
        }

        debounce(() => fetchData(element, search, callback))()
      }
    }

    // This snippet allows us to set the displayed option's html shown in the
    // slim select. To do so add `data-usehtml=true` to the select tag and
    // data-innerhtml="<h1>Some HTML</h1>" to each of the option tags.
    if (element.dataset["usehtml"]) {
      options.valuesUseText = false
      options.data = Array.from(element.children).map(function (option) {
        return {
          innerHTML: option.dataset.innerhtml,
          text: option.text,
          value: option.value,
          selected: option.selected,
          class: option.className,
          placeholder: option.value === "",
        }
      })
    }

    if (this.data.has("addable")) {
      options.addable = (value) => {
        // return false or null if you do not want to allow value to be submitted
        if (value === "") {
          return false
        }
        // Return the value string
        return value // Optional - value alteration // ex: value.toLowerCase()
        // Optional - Return a valid data object. See methods/setData for list of valid options
        // return {
        //   text: value,
        //   value: value.toLowerCase(),
        // }
      }
    }

    this.widget = new SlimSelect(options)
    // Trigger add on Enter, not Ctrl + Enter
    let ref = this.widget.select.main
    ref.slim.search.input.addEventListener("keyup", (event) => {
      if (ref.addable && event.key === "Enter") {
        ref.slim.search.addable.click()
        ref.slim.search.input.value = ""
      }
    })
  }
}
