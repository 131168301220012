import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["destination"]

  replace(event) {
    const actionValue = event.target.value
    const urlReplaceValue = event.target.dataset.toePreviewValue

    if (urlReplaceValue) {
      this.destinationTarget.href = `${urlReplaceValue}?template=${actionValue}`
    }
  }
}
