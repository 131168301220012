import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["donutChart"]

  connect() {
    const element = this.donutChartTarget
    const percent = Math.round(element.dataset.progressPercentage)

    if (percent > 100) {
      percent = 100
    } else if (percent < 0) {
      percent = 0
    }
    var deg = Math.round(360 * (percent / 100))

    if (percent > 50) {
      document.getElementById("pie").style.clip = "rect(auto, auto, auto, auto)"
      document.getElementById("pie-right-side").style.transform = "rotate(180deg)"
    } else {
      document.getElementById("pie").style.clip = "rect(0, 1em, 1em, 0.5em)"
      document.getElementById("pie-right-side").style.transform = "rotate(0deg)"
    }

    document.getElementById("pie-right-side").style.borderWidth = "0.1em"
    document.getElementById("pie-left-side").style.borderWidth = "0.1em"
    document.getElementById("shadow").style.borderWidth = "0.1em"
    document.getElementById("percentage-num").innerText = percent + "%"
    document.getElementById("pie-left-side").style.transform = "rotate(" + deg + "deg)"
  }
}
