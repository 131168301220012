// Controller to handle manipulating turbo frame src attribute

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["turboFrame"]
  static values = {
    targetFrameId: String, // The dom id of the turboframe to set the src attribute of
    param: String, // The name of the parameter to set in setSrcParamFromValue
  }

  // Set the src attribute of the target turbo frame to the existing url with
  // the param updated and reload the frame.
  //
  // Example usage:
  //
  // ```
  //  <select
  //    data-remote-url="/admin/clients.json"
  //    data-controller="turbo-frame-src"
  //    data-action="turbo-frame-src#setSrcParamFromValue"
  //    data-turbo-frame-src-target-frame-id-value="client_chargeable_select"
  //    data-turbo-frame-src-param-value="client_id"
  //    name="payment[client_id]"
  //  >
  //  </select>
  //
  //  <turbo-frame
  //    id="client_chargeable_select"
  //    src="/admin/payments/client_chargeable_select?client_id=123"
  //  >
  //  </turbo-frame>
  // ```
  // When a new value is selected the turbo-frame will have it's `client_id`
  // param updated, and the contents of the frame will be reloaded.
  //
  //
  // NOTE this method should only really be used with slim selects populated
  // by `remote-url`, as we can't set the `data` attribute on each option tag.
  // If you want to update a turbo frame's src from the value of a non lazy
  // loaded select, or other field, create a new method that reads the whole
  // src url from a data attribute on the field or option tag.
  setSrcParamFromValue() {
    let value = this.element.value
    let paramName = this.paramValue
    let frame = document.getElementById(this.targetFrameIdValue)
    let srcPath = frame.src

    let url = new URL("https://example.com" + srcPath)
    url.searchParams.set(paramName, value)

    frame.src = url.pathname + url.search
    frame.reload()
  }

  // Check if the turbo frame is present on the page before attempting to set
  // it's src attribute.
  setSrcParamFromValueIfFrameExists() {
    if(document.getElementById(this.targetFrameIdValue)) {
      this.setSrcParamFromValue()
    }
  }

  // Used when the value of a select is a url we want to request a turbo frame
  // for.
  //
  // Example usage:
  //
  // ```
  // <select
  //   data-controller="turbo-frame-src"
  //   data-action="turbo-frame-src#setSrcFromValue"
  //   data-turbo-frame-src-target-frame-id-value="widget_options"
  // >
  //  <option value="/widgets/1">Widget #1</option>
  //  <option value="/widgets/2">Widget #2</option>
  // </select>
  //
  // <turbo-frame id="widget_options"></turbo-frame>
  //
  setSrcFromValue() {
    let value = this.element.value
    let frame = document.getElementById(this.targetFrameIdValue)
    frame.src = value

    if (frame.src) {
      frame.reload()
    } else {
      // Placeholder value has been selected, remove the content of the frame
      frame.innerHTML = ""
    }
  }
}
