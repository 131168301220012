import BaseController from "./base_controller"

export default class extends BaseController {
  static targets = ["category"]

  search() {
    if (this.searchInput.length === 0 || this.searchInput.length >= 3) {
      window.clearTimeout(this.timeout)

      this.timeout = window.setTimeout(() => {
        this.category = ""
        this.submit()
      }, 1500)
    }
  }

  set category(value) {
    this.categoryTarget.value = value
  }
}
