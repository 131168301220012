import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["form", "eventUriField"]
  static values = {
    allowedOrigin: String,
    meetingUrl: String,
  }

  connect() {
    this.calendlyListener = this.calendlyCallback.bind(this)
    window.addEventListener("message", this.calendlyListener)
  }

  disconnect() {
    window.removeEventListener("message", this.calendlyListener)
  }

  initPopup(event) {
    event.preventDefault()

    Calendly.initPopupWidget({ url: this.meetingUrlValue })
  }

  isCalendlyEvent(e) {
    if (e.origin !== this.allowedOriginValue) {
      return false
    }

    return e.data.event && e.data.event.indexOf("calendly") === 0
  }

  calendlyCallback(e) {
    if (this.isCalendlyEvent(e)) {
      if (e.data.event === "calendly.event_scheduled") {
        let eventUri = e.data.payload.event.uri
        this.eventUriFieldTarget.value = eventUri
        this.formTarget.submit()
      }
    }
  }
}
