import { Controller } from "@hotwired/stimulus"
import { convertToBoolean } from "../../helpers/method-helper"

export default class extends Controller {
  static targets = ["content", "sidebar", "parent", "icon", "close"]

  connect() {
    this.loadState = this.load("case-detail-page-sidebar-opened")
    this.mobileLoad = this.sidebarTarget.dataset.mobile

    if (!convertToBoolean(this.mobileLoad)) {
      if (this.loadState == "false") {
        this.expand()
      } else {
        this.collapse()
      }
    } else {
      this.expand()
    }
  }

  toggle() {
    if (this.contentTarget.dataset.expanded === "1") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    this.contentTarget.dataset.expanded = 0
    this.contentTarget.classList.remove("lg:col-span-12")
    this.contentTarget.classList.add("lg:col-span-9")
    this.parentTarget.classList.remove("xlg:col-span-8", "xlg:col-start-3", "max-w-910px")
    this.parentTarget.classList.add("xlg:col-span-10", "xlg:col-start-2", "max-w-1142px")
    this.sidebarTarget.classList.remove("hidden")
    this.iconTarget
      .getElementsByClassName("active-user-group")[0]
      .classList.remove("hidden")
    this.iconTarget.getElementsByClassName("user-group")[0].classList.add("hidden")
    document
      .getElementsByTagName("body")[0]
      .classList.add("overflow-y-hidden", "lg:overflow-y-auto")
    this.save("case-detail-page-sidebar-opened", true)
  }

  expand() {
    this.contentTarget.dataset.expanded = 1
    this.contentTarget.classList.remove("lg:col-span-9")
    this.contentTarget.classList.add("lg:col-span-12")
    this.parentTarget.classList.remove(
      "xlg:col-span-10",
      "xlg:col-start-2",
      "max-w-1142px"
    )
    this.parentTarget.classList.add("xlg:col-span-8", "xlg:col-start-3", "max-w-910px")
    this.sidebarTarget.classList.add("hidden")
    this.iconTarget.getElementsByClassName("active-user-group")[0].classList.add("hidden")
    this.iconTarget.getElementsByClassName("user-group")[0].classList.remove("hidden")
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-y-hidden", "lg:overflow-y-auto")
    this.save("case-detail-page-sidebar-opened", false)
  }

  load(key) {
    let value = sessionStorage.getItem(key)
    try {
      return value
    } catch (error) {
      return null
    }
  }

  save(key, value) {
    try {
      sessionStorage.setItem(key, value)
    } catch (error) {
      return null
    }
  }
}
