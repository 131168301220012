import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
  static targets = ["bell", "active", "none"]

  connect() {
    useMutation(this, { childList: true, subtree: true })
  }

  mutate(entries) {
    this._toggleUnread()

    if (this.hasNoneTarget) {
      this._toggleNone()
    }
  }

  _toggleUnread() {
    if (this._unreadNumber > 0) {
      this.activeTarget.classList.remove("hidden")
    } else {
      this.activeTarget.classList.add("hidden")
    }
  }

  _toggleNone() {
    if (this._notifications.length > 1) {
      this.noneTarget.classList.add("hidden")
    } else {
      this.noneTarget.classList.add("show")
    }
  }

  get _notifications() {
    const entries = this.element.querySelector("#header_notification_entries")

    if (entries) {
      return Array.from(entries.children)
    } else {
      return []
    }
  }

  get _unreadNumber() {
    if (this._notificationsCounter) {
      return parseInt(this._notificationsCounter.dataset.number)
    } else {
      return 0
    }
  }

  get _notificationsCounter() {
    return this.element.querySelector("#unread_header_notifications_counter")
  }
}
