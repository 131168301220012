import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultForm", "amendForm"]

  connect() {
    if (this.hasDefaultFormTarget) {
      this.toggleInputs(
        this.defaultFormTarget,
        this.defaultFormTarget.classList.contains("hidden")
      )
    }

    if (this.hasAmendFormTarget) {
      this.toggleInputs(
        this.amendFormTarget,
        this.amendFormTarget.classList.contains("hidden")
      )
    }
  }

  changeDeliveryDate(event) {
    event.preventDefault()

    this.defaultFormTarget.classList.add("hidden")
    this.amendFormTarget.classList.remove("hidden")

    this.toggleInputs(this.defaultFormTarget, true)
    this.toggleInputs(this.amendFormTarget, false)
  }

  cancelChanges(event) {
    event.preventDefault()

    this.defaultFormTarget.classList.remove("hidden")
    this.amendFormTarget.classList.add("hidden")

    this.toggleInputs(this.defaultFormTarget, false)
    this.toggleInputs(this.amendFormTarget, true)
  }

  toggleInputs(target, disabled) {
    target.querySelectorAll("input, textarea, select").forEach((input) => {
      input.disabled = disabled
    })
  }
}
