import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  connect() {
    useDispatch(this)
  }

  change(event) {
    const selectedOption = event.target.selectedOptions[0]
    // dispatch a custom event: quote--currency_change:changed
    // to indicate that user has changed currency on quote
    this.dispatch("changed")
  }
}
