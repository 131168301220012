import { Dropdown } from "tailwindcss-stimulus-components"
import Rails from "@rails/ujs"

export default class extends Dropdown {
  static values = { url: String }

  async loadContent() {
    const _this = this

    if (!_this.openValue) {
      await fetch(this.urlValue, {
        method: "GET",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "text/html",
        },
      })
        .then((response) => response.text())
        .then((html) => {
          _this.menuTarget.innerHTML = html
          _this.toggle()
        })
    } else {
      _this.toggle()
    }
  }
}
