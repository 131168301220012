import flatpickr from "flatpickr"

function bindDateTimePickers() {
  ;[...document.querySelectorAll('[data-type="time"]')].forEach((time) => {
    flatpickr(time, {
      enableTime: true,
      enableSeconds: true,
      noCalendar: true,
      altInput: true,
    })
  })
  ;[...document.querySelectorAll('[data-type="datetime"]')].forEach((time) => {
    flatpickr(time, {
      enableTime: true,
      altInput: true,
    })
  })
  ;[...document.querySelectorAll('[data-type="date"]')].forEach((date) => {
    flatpickr(date, {
      altInput: true,
    })
  })
  document.querySelectorAll('[data-type="inline-date"]').forEach((date) => {
    if (!date.classList.contains("flatpickr-input")) {
      flatpickr(date, {
        altInput: true,
        inline: true,
        monthSelectorType: "static",
        minDate: "today",
        disable: [
          (date) => {
            return date.getDay() === 0 || date.getDay() === 6
          },
        ],
        onReady(_, __, calander) {
          const klass = date.dataset.flatpickerClass
          if (klass) calander.calendarContainer.classList.add(klass)
        },
      })
    }
  })
}

document.addEventListener("turbo:load", bindDateTimePickers)
document.addEventListener("turbo:render", bindDateTimePickers)

// if turbo has been disabled (while in development for example), use the standard
// load event instead
if (!window.Turbo) window.addEventListener("load", bindDateTimePickers)
