import { Controller } from "@hotwired/stimulus"

class LineItem {
	constructor(element) {
		this.element = element
	}

	get price() {
		return parseFloat(this.priceField.value.replace(/[^\d.]/g, ''))
	}

	get priceField() {
		return this.element.querySelector("input[name*=unit_amount]")
	}
}

export default class extends Controller {
	static targets = ["disbursement", "unitAmount"]

	calculateDisbursementFee(event) {
		event.preventDefault()

		let unitAmountFieldId = event.target.dataset.unitAmountFieldId
		let unitAmountField = document.getElementById(unitAmountFieldId)

		// We have two fields with the same id, the hidden field is what gets
		// submitted so we need to set that too
		let hiddenAmountField = document.querySelector(`input[type="hidden"]#${unitAmountFieldId}`)

		let disbursementsTotal = this.disbursements.map(d => d.price).reduce((a, b) => a + b, 0)
		let disbursmentsFee = disbursementsTotal * 0.05

		unitAmountField.value = this.formatAmount(disbursmentsFee.toFixed(2))
		hiddenAmountField.value = disbursmentsFee.toFixed(2)
	}

	get disbursements() {
		return this.disbursementTargets.map(d => new LineItem(d))
	}

  formatAmount(amount) {
    return (+amount).toLocaleString(undefined, {
      style: "currency",
      currency: this.currentCurrency.toUpperCase(),
    })
  }

	get currentCurrency() {
    return document.getElementById("create_quote_form_currency").value
	}
}
