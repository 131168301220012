import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "button"]

  copy(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.sourceTarget.value)

    this.copied()
  }

  copied() {
    const originalHTML = this.buttonTarget.innerHTML

    if (!this.hasButtonTarget) {
      return
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerText = this.data.get("successContent")

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = originalHTML
    }, 2000)
  }
}
