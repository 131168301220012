// implementation copied from
// https://discuss.hotwired.dev/t/break-out-of-turbo-frame-on-devise-401-redirect/2479/7

// NOTE: This can't be loaded at the top level of the document tree as it results in incorrect
// functionality when trubo event are NOT loading into a turboframe (i.e. target: _top and form
// submission)
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    addEventListener("turbo:before-fetch-response", this.intercept)
  }

  intercept(event) {
    const { url } = event.detail.fetchResponse.response
    // I was not able to intercept the 401 response, only the one after which.
    // This is response is a 200 response. I had to use the url to match.
    // This method is sufficient for my project, but not enough to be an general solution
    if (url.match("sign_in")) {
      event.preventDefault()
      // Here you may ask, "shouldn't we redirect to the url in the url variable above?"
      // The reason being, devise stores the location before signing out by default,
      // to redirect the user back after a successful authorization. The url before signing out
      // here, is the turbo frame request, which is part of the the current page, therefore may not
      // an ideal target for the user to visit. The current page, which is the container of the turbo
      // frame, is a more appropriate candidate.
      window.location.reload()
    }
  }
}
