import { Controller } from "@hotwired/stimulus"
import Refiner from "refiner-js"

export default class extends Controller {
  static values = {
    uuid: String,
    uemail: String,
    uname: String,
    projectid: String,
    formid: String,
    signature: String,
  }
  connect() {
    Refiner("setProject", this.projectidValue)
    Refiner(
      "identifyUser",
      {
        id: this.uuidValue,
        email: this.uemailValue,
        name: this.unameValue,
      },
      {
        signature: this.signatureValue,
      }
    )
    Refiner("showForm", this.formidValue)
  }
}
