import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editor"]

  connect() {
    this.loadTrixText()
  }

  loadTrixText() {
    if (!localStorage[this.localStorageKey] || localStorage[this.localStorageKey] == "") {
      return
    }

    if (this.isTrixEditor) {
      this.editorTarget.editor.loadJSON(JSON.parse(localStorage[this.localStorageKey]))
    } else {
      this.editorTarget.value = localStorage[this.localStorageKey]
    }
  }

  saveToLocalStorage() {
    if (this.isTrixEditor) {
      localStorage[this.localStorageKey] = JSON.stringify(this.editorTarget.editor)
    } else {
      localStorage[this.localStorageKey] = this.editorTarget.value
    }
  }

  clearLocalStorage(event) {
    const detail = event.detail
    const success = detail.success
    const url = detail.fetchResponse.response.url
    // If we're timed out we still get a success status as we're redirected,
    // so we need to check the url we're redirect to.
    const timedout = url && url.includes("sign_in")

    if (success && !timedout) {
      localStorage[this.localStorageKey] = ""
    }
  }

  get localStorageKey() {
    return "messageHTML" + window.location.pathname
  }

  get isTrixEditor() {
    return !!this.editorTarget.editor
  }
}
