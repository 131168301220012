import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 0,
    }
  }

  destroy() {
    setTimeout(() => { this.element.remove() }, this.delayValue)
  }
}
