/**
 * Select and active confirm button after select a target
 * Add `data-select-buttons-target="confirmButton"` to confirm buttons
 * Add `data-select-buttons-target="selectButton"` to select buttons
 * Add `data-action="click->select-buttons#change"` to select buttons
 * Add `data-value="example"` to add value for each option of select button
 * Add `data-show-on-selected-value="example"` to each confirm button to show/hide it
 * where selectButton will update style when select and show/hide the confirm button based on the selected option
 *
 * Example usage:
 *
 * ```
 * <section data-controller="select-buttons">
 *  <div data-select-buttons-target="selectButton" data-action="click->select-buttons#change" data-value="example1">button 1</div>
 *  <div data-select-buttons-target="selectButton" data-action="click->select-buttons#change" data-value="example2">button 2</div>
 *
 *  <div data-select-buttons-target="confirmButton" data-show-on-selected-value="example1">confirm button</div>
 *  <div data-select-buttons-target="confirmButton" data-show-on-selected-value="example2">confirm button</div>
 * </section>
 * ```
 **/
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectButton", "confirmButton"]

  connect() {
    // Hide all confirm button except the first one
    this.confirmButtonTargets.forEach((element, index) => {
      if (index !== 0) {
        element.classList.add("hidden")
      }
    })
  }

  change(event) {
    const value = event.target.dataset.value

    // Set disable style for all other selectButton elements
    this.selectButtonTargets.forEach((button) => {
      const isActive = button === event.target
      button.classList.toggle("bg-blue-vibrant", isActive)
      button.classList.toggle("border-blue-vibrant", isActive)
      button.classList.toggle("text-white", isActive)
      button.classList.toggle("text-gray-light", !isActive)
    })

    // Activate the confirmButton and show/hide based on options
    this.confirmButtonTargets.forEach((element) => {
      if (element.dataset.showOnSelectedValue === value) {
        element.classList.remove("opacity-40")
        element.classList.remove("pointer-events-none")
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }
}
