import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.data.get("target") !== undefined) {
      this.target = document.getElementById(this.data.get("target"))
    }
  }

  load(event) {
    if (this.target !== undefined) {
      event.preventDefault()
      const linkElement = event.target.closest("[data-action~='content-frame#load']")
      const url = linkElement.dataset.contentFrameUrl
      this.target.setAttribute("url", url)
    }
  }
}
