import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "tab", "card"]
  connect() {
    // call this here to correct set the width of page reload
    this.resizeHeader()
  }

  resizeHeader() {
    const header = this.headerTarget
    const _this = this
    let isSet = false

    this.tabTargets.forEach(function (tab) {
      if (!tab.classList.contains("hidden")) {
        if (isSet) throw "Multiple tabs visible unable to set header size"

        const cardsForTab = _this.cardTargets.filter(function (card) {
          return tab.contains(card)
        })

        let min, max

        cardsForTab.forEach(function (card) {
          const box = card.getBoundingClientRect()
          if (min === undefined || box.left < min) min = box.left
          if (max === undefined || box.right > max) max = box.right
        })

        header.style.width = max - min + "px"
        isSet = true
      }
    })

    if (!isSet) header.style.width = null
  }
}
