/**
 * Update placeholder text for textarea tag depending on checkboxes checked
 *
 * Group your checkboxes and textarea in one div
 * Add `data-controller="provide-comments"` to the container div
 * Add `provide-comments-target="checkbox"` to each checkbox
 * Add `provide_comments_target="textarea"` to the textarea
 *
 * If all checkboxes were checked the textarea placeholder will change to optional or mandatory if not
 *
 * Example usage:
 *
 * ```
 * <div data-controller="show-on-selected">
 *  <input type="checkbox" name="checkbox1" provide-comments-target="checkbox">
 *  <input type="checkbox" name="checkbox2" provide-comments-target="checkbox">
 *  <textarea name="textarea" rows="4" cols="40" provide_comments_target="textarea"></textarea>
 * </div>
 * ```
 **/
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "textarea"]

  connect() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", this.checkTextMandatory)
    })
    this.checkTextMandatory()
  }

  checkTextMandatory = () => {
    const checkedCheckboxes = this.checkboxTargets.filter((checkbox) => checkbox.checked)
    if (checkedCheckboxes.length === this.checkboxTargets.length) {
      this.textareaTarget.placeholder =
        "Please provide any additional comments (Optional)"
    } else {
      this.textareaTarget.placeholder =
        "Please provide any additional comments (Mandatory)"
    }
  }
}
